import { createApp } from '@o-din/app';

import Maintenance from '@o-din-maintenance/src/o-din-maintenance';
import Ppr from '@o-din-ppr/src/o-din-ppr';
import Rounds from '@o-din-rounds/src/o-din-rounds';
import Lk from '@o-din-lk/src/o-din-lk';
import Ku from '@o-din-ku/src/o-din-ku';
import Report from '@o-din-report/src/o-din-report';

createApp('#app', [Maintenance, Ppr, Rounds, Lk, Ku, Report]);
